html, #root, body, .Grommet, .App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 340px) {
  body {
    font-size: 0.9em;
  }
}

